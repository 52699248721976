<template>
  <div class="h-full flex column kanban-module crm-module">
    <div class="component-header wrapper w-full flex-zero">
      <e-row>
        <e-col class="flex">
          <div class="app-input-group">
            <div class="i-group only-icon">
              <u-btn @click="() => view.tipo = 'kanban'" :class="{active: view.tipo === 'kanban'}" icon="columns" icon-style="regular" icon-type="fa" title="Kanban" no-caps></u-btn>
              <u-btn @click="() => view.tipo = 'lista'" :class="{active: view.tipo === 'lista'}" icon="bars" title="Lista" icon-type="fa" icon-style="regular" no-caps></u-btn>
            </div>
          </div>
          <u-btn :disable="loading" @click="novoNegocio(funil, null, funis)" label="+ Negócio"
            class="app-btn bg-green bold text-white m-l-sm no-shadow" size="sm" no-caps />

        </e-col>
        <e-col class="flex justify-end">
          <funil-select :loading="loading" :funis="funis" :active="funil" @mudarFunil="mudarFunil" />
        </e-col>
      </e-row>
    </div>

    <div class="kanban-container" v-if="view.tipo === 'kanban' && !loading">
      <div v-if="!!kanban" class="kanban-content">
        <div class="kanban-funil-item" v-for="etapa in funil.etapas">
          <div class="funil-header">
            <div class="funil-header-content">
              <div class="full-width">
                <div>
                  <div class="etapa-title">{{ etapa.nome }}</div>
                </div>
                <div class="etapa-subtitle">
                  <div class="m-r-sm">
                    R$ {{getTotals(kanban[etapa.id]) | moeda}}
                  </div>
                  <div v-if="!!kanban[etapa.id].negocios">
                     {{kanban[etapa.id].negocios.length}} negócios
                  </div>
                </div>
              </div>
              <svg class="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path class="arrow__right" fill="#F7F7F7" d="M0 0h16v56H0z"></path>
                  <path class="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path>
                  <path class="arrow__left" fill="#F7F7F7" d="M0 1l8 27-8 27z"></path>
                </g>
              </svg>
            </div>
          </div>
          <draggable v-if="(!loadingKanban  && !!kanban)" :list="kanban[etapa.id].negocios" :id="etapa.id" :move="dropzoneDrop" class="funil-cards" group="kanban">
            <div class="card" draggable="true"
              v-for="(item, i) in kanban[etapa.id].negocios">
                <div class="card-content" @click="abrir(item.id, funil.id, funis)" :style="`background-color: ${item.cor ? '#' + item.cor : ''}`">
                  <div style="width: 100%;">
                      <span class="etapa-title">{{item.titulo}}</span>
                      <div class="etapa-subtitle" style="margin: 10px 0px 10px 0;">{{(item.pessoa.name.split(' ')[0] ?? item.organizacao.name.split(' ')[0] ?? '')}}</div>
                      <div style="display: flex; flex-direction: row;">
                        <u-icon name="user" type="fa" class="m-r  font-14 text-blue-grey-5"/>
                        <div data-test="pipeline-deal-value" class="">R$ {{item.valor | moeda}}</div>
                      </div>
                  </div>
                  <!-- <div class="">
                    <div data-test="activity-status-indicator-3" data-status="NONE" class="sc-fmPOXC cOihAk">
                      <svg width="16px" height="16px" viewBox="0 1 16 16" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" role="img">
                        <g id="triangle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <path
                            d="M0.140609415,13.4207204 L7.09307041,1.50870657 C7.38538348,1.00787136 8.0283572,0.838830762 8.52919241,1.13114383 C8.68547883,1.22236058 8.8155384,1.35242015 8.90675515,1.50870657 L15.8592161,13.4207204 C16.1515292,13.9215556 15.9824886,14.5645293 15.4816534,14.8568424 C15.3210198,14.9505964 15.1383656,15 14.9523738,15 L1.04745178,15 C0.467552796,15 -0.00254821717,14.529899 -0.00254821717,13.95 C-0.00254821717,13.7640082 0.0468554312,13.581354 0.140609415,13.4207204 Z M6.99075153,4.95802382 L6.99075153,9.97901191 L9.00924646,9.97901191 L9.00924646,4.95802382 L6.99075153,4.95802382 Z M6.99075153,10.9832095 L6.99075153,12.9916048 L9.00924646,12.9916048 L9.00924646,10.9832095 L6.99075153,10.9832095 Z"
                            id="Combined-Shape" fill="#FFDF5C"></path>
                        </g>
                      </svg>
                    </div>
                  </div> -->
                </div>
            </div>
            <u-btn icon="plus" @click="novoNegocio(funil, null, etapa.id)" icon-type="fa" icon-style="light"
              color="grey-9" dense class="add-btn b-radius-3px full-width no-shadow" flat />
          </draggable>
          <div class="funil-cards" v-else>
            <div class="card">
              <div class="card-content_">
                <u-fake-loader />
                <u-fake-loader width="60%" />
                <u-fake-loader width="10%" />
                <u-fake-loader width="10%" />
                <u-fake-loader width="10%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kanban-container flex items-center justify-center" v-else-if="view.tipo === 'kanban' && loading">
      <sl-loading content="CRM" />
    </div>
    <crm-lista v-if="view.tipo === 'lista'" />
  </div>
</template>

<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import FunilSelect from "@/components/negocio/components/inputs/FunilSelect"
import { find } from "@/domain/negocio/services/funil"
import { listByFunil, updateNegocio, updateFunilEtapaFromNegocio } from "@/domain/negocio/services/negocio"
import SlLoading from "@/components/layout/components/Loading"
import draggable from "vuedraggable"
import { UFakeLoader } from "uloc-vue"
import novoNegocio from "components/negocio/windows/novoNegocio"
import windowNegocio from "components/negocio/windows/negocio"
import CrmLista from "components/negocio/components/Lista.vue";

export default {
  name: "Kanban",
  provide: function () {
    return {
      container: this
    }
  },
  data() {
    return {
      loading: true,
      funis: null,
      funil: null,
      loadingKanban: true,
      kanban: null,
      view: {
        tipo: 'kanban'
      },
      totals: {
        qtdNegocios: [],
        valorTotal: []
      },
      dropzoneActive: false
    }
  },
  components: {
    CrmLista,
    SlLoading,
    FunilSelect,
    ERow,
    ECol,
    draggable,
    UFakeLoader
  },
  computed: {

  },
  mounted() {
    this.load()
  },
  methods: {
    getTotals(item) {
      // this.totals = []
      // var kanban = [JSON.parse(JSON.stringify(this.kanban))]
      // this.funil.etapas.forEach(e => {
      //   totals.qtdNegocios[e.id] = kanban[e.id].negocios.length;
      //   totals.valorTotal[e.id] = !!kanban[e.id].negocios.length ? kanban.negocios.reduce((prev, acc) => prev.valor + acc.valor) : 0;
      // });
      var total = 0;
      item.negocios.forEach(e => {
        total += +e.valor
      })
      return total
    },
    novoNegocio,
    abrir (id, funilId) {
      this.$router.push({name: 'negocio', params: {id: id}})
      /*let router = this.$router || this.router
      let routeData = router.resolve({name: 'negocio', params: {id: id}})
      window.open(routeData.href, '_blank')*/
    },
    load(id) {
      this.loading = true
      id = typeof id === 'undefined' ? null : id
      find(id)
        .then(response => {
          if (id) {
            this.funil = response.data
          } else {
            console.log(response.data)
            this.funis = response.data
            this.funil = this.active()
          }
          this.$nextTick(() => {
            this.loading = false
            this.loadNegocios(this.funil)
          })
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    },
    loadNegocios(funil) {
      this.loadingKanban = true
      listByFunil(funil.id, 20, 1, '')
        .then(response => {
          for (const key in response.data) {
            if (Object.hasOwnProperty.call(response.data, key)) {
              response.data[key].negocios.map(n => ({
                ...n,
                dropzoneActive: false,
                dropzoneMoving: false
              }));
            }
          }
          this.kanban = response.data

          this.$nextTick(() => {
            this.$uloc.loading.hide()
            this.loadingKanban = false
          })
        })
        .catch(error => {
          console.log(error)
          this.$uloc.loading.hide()
          this.alertApiError(error)
        })
    },
    active() {
      if (!this.funis) return null
      const first = this.funis.slice().sort((a, b) => {
        return a.order - b.order
      })
      if (typeof first[0] !== 'undefined') {
        return first[0]
      }
      return null
    },
    mudarFunil(funil) {
      this.load(funil.id)
    },
    dropzone(e, active, event = null) {
      this.dropzoneActive = active
      e.dropzoneActive = active
      if (event === 'dragstart') {
        e.dropzoneMoving = true
        this.dropactiveElement = e
      }
    },
    dropzoneDrop(event) {
      let negocioMoved = event.draggedContext.element;
      let fromEtapa = event.to.id;

      if(negocioMoved.id !== fromEtapa) {
        updateFunilEtapaFromNegocio(negocioMoved.id, fromEtapa)
        .then(response => {
          this.$nextTick(() => {
            this.loadingKanban = false
          })
        })
        .catch(error => {
          this.alertApiError(error)
          this.load()
        })
      }
      // this.dropzoneActive = active
      // // @TODO: Ajustar forma de ordenação, isso é temporário
      // if (e.order > this.dropactiveElement.order) {
      //   this.dropactiveElement.order = e.order + 1
      // } else {
      //   this.dropactiveElement.order = e.order - 1
      //   e.order = e.order - 1
      // }
      // this.$nextTick(() => {
      //   let i = 0
      //   this.computedEtapas.map(_e => {
      //     i++
      //     _e.order = i
      //   })
      // })
      // e.dropzoneActive = active
      // e.dropzoneMoving = false
      // console.log(event)
      // this.dropactiveElement.dropzoneMoving = false
      // delete this.dropactiveElement

    },
  }
}
</script>

