<template>
  <div class="h-full flex column kanban-module">
  <div class="filters">
    <div class="sl-v2-table-filters full-width">
      <e-row class="e-input-modern size1">
        <e-col style="min-width: 150px" class="m-l m-r">
          <erp-s-field
              view="ll"
              label="Busca"
          >
            <erp-input placeholder="Busca Inteligente" v-on:keyup.enter="pesquisar" shortkey="F2" v-model="filters.search" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 100px" class="m-l m-r">
          <erp-s-field
              view="ll"
              label="ID"
          >
            <erp-input v-on:keyup.enter="pesquisar" shortkey="F3" v-model="filters.id" />
          </erp-s-field>
        </e-col>
        <e-col style="min-width: 150px" class="m-l m-r" v-show="false">
          <erp-s-field view="ll" label="Status">
            <status-select v-model="filters.status" placeholder="Somente ativos" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 80px">
          <e-btn @click="pesquisar" label="Filtrar" />
          <!--                  <u-btn @click="pesquisar" color="grey" size="sm" class="app-btn erp-btn text-white" no-caps>Filtrar</u-btn>-->
        </e-col>
        <e-col class="flex items-end content-end justify-end">
          <u-btn @click="showFilters = !showFilters" color="blue-grey-1" size="sm" class="app-btn text-blue-grey-5" no-caps>
            <!--    Mais filtros-->
            <u-icon name="filter" class="m-l__ m-r-sm fin-icon-filter" type="fa"/>
            <span v-if="!showFilters">Mais Filtros</span>
            <span v-else>Menos Filtros</span>
          </u-btn>
        </e-col>
      </e-row>
      <e-row>
        <filtros-adicionais :filters="filters" v-show="showFilters" :disable="{
          internalStatus: true
        }" />
      </e-row>
    </div>
  </div>
    <div class="kanban-container" v-if="!loading">
      <div class="kanban-content">
        <div class="kanban-funil-item" v-for="grid in filteredGrids">
          <div class="funil-header">
            <div class="funil-header-content">
              <div>
                <div class="etapa-title" :class="'etapa-s-code-' + grid.code">{{ grid.name }} <span style="font-size: 10px; margin-left: 5px">({{filteredTasks[grid.id].length}})</span> </div>
              </div>
              <div class="etapa-subtitle">
                <!--                  <div class="m-r-sm">
                                    R$ {{getTotals(kanban[etapa.id]) | moeda}}
                                  </div>
                                  <div v-if="!!kanban[etapa.id].negocios">
                                     {{kanban[etapa.id].negocios.length}} negócios
                                  </div>-->
              </div>
            </div>
          </div>
          <draggable v-if="!loadingKanban" :list="filteredTasks[grid.id]" :id="grid.id" :move="dropzoneDrop" @end="onDragEnd"
                     class="funil-cards" group="kanban">
            <div class="card" draggable="true"
                 v-for="(item, i) in filteredTasks[grid.id]" :data-id="item.id" @click="openTask(item)">
              <context-menu-board :task="item" />
              <div class="card-content"
                   :style="`background-color: ${item.cor ? '#' + item.cor : ''}`">
                <div style="width: 100%;">
                  <span class="task-title">{{ item.description }}</span>
                  <div class="task-b-line1">
                    <div class="priority priority-3" v-if="item.priorityInternal === 3">Alta 🔥</div>
                    <div class="priority priority-4" v-if="item.priorityInternal === 4">Urgente ⚠️</div>
                    <div v-if="item.cache && item.cache.participants">
                      <task-group-persons :persons="item.cache.participants" no-click />
                    </div>
                    <span v-else></span>
                  </div>
                  <div v-if="item.deadline" class="task-due-line">
                    <span class="dl-dt">{{ item.deadline|formatDate('dd/MM/yyyy') }}</span> <span class="dl-hr">{{ item.deadline|formatDate('HH:mm') }}</span>
                    <span class="m-l-xs trask-atraso-lbl" v-if="isOpen(item) && atraso(item.deadline) > 0"><i
                        class="fas fa-exclamation-triangle m-r-xs"></i> {{ atraso(item.deadline) }} dia{{ atraso(item.deadline) > 1 ? 's' : '' }}</span>
                  </div>
<!--                  <div class="etapa-subtitle" style="margin: 10px 0px 10px 0;">
                    {{ (item.pessoa.name.split(' ')[0] ?? item.organizacao.name.split(' ')[0] ?? '') }}
                  </div>
                  <div style="display: flex; flex-direction: row;">
                    <u-icon name="user" type="fa" class="m-r  font-14 text-blue-grey-5"/>
                    <div data-test="pipeline-deal-value" class="">R$ {{ item.valor | moeda }}</div>
                  </div>-->
                </div>
              </div>
            </div>
            <u-btn icon="plus" icon-type="fa" icon-style="light"
                   color="grey-9" dense class="add-btn b-radius-3px full-width no-shadow" flat/>
          </draggable>
          <div class="funil-cards" v-else>
            <div class="card">
              <div class="card-content_">
                <u-fake-loader/>
                <u-fake-loader width="60%"/>
                <u-fake-loader width="10%"/>
                <u-fake-loader width="10%"/>
                <u-fake-loader width="10%"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kanban-container flex items-center justify-center" v-else>
      <sl-loading content="Tasks"/>
    </div>
  </div>
</template>

<script>
import {board, boardTaskUpdate} from "@/domain/tarefas/services"
import SlLoading from "@/components/layout/components/Loading"
import draggable from "vuedraggable"
import {UFakeLoader} from "uloc-vue"
import TaskGroupPersons from "components/sltarefas/components/others/TaskGroupPersons.vue";
import {differenceInHours, parseISO} from "date-fns";
import ContextMenuBoard from "components/console/components/suporte/tarefas/include/BoardContext.vue";
import {ErpInput, ErpSField} from "@/plugins/uloc-erp";
import ECol from "components/layout/components/Col.vue";
import FiltrosAdicionais from "components/console/components/suporte/tarefas/include/ListFilters.vue";
import EBtn from "@/plugins/uloc-erp/components/button/EBtn";
import ERow from "components/layout/components/Row.vue";
import StatusSelect from "components/suporte/components/task/include/StatusSelect.vue";
import filters from "@/domain/tarefas/helpers/filtersConsole";

export default {
  name: "Kanban",
  provide: function () {
    return {
      container: this
    }
  },
  data() {
    return {
      loading: true,
      grids: [],
      tasks: [],
      loadingKanban: true,
      dropzoneActive: false,
      showFilters: false,
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  created() {
    document.body.classList.add('kanban-module-body')
  },
  destroyed() {
    document.body.classList.remove('kanban-module-body')
  },
  components: {
    StatusSelect, ERow, EBtn, ErpInput, FiltrosAdicionais, ECol, ErpSField,
    ContextMenuBoard,
    TaskGroupPersons,
    SlLoading,
    draggable,
    UFakeLoader
  },
  computed: {
    filteredGrids() {
      return this.grids.filter(s => s.type === 'opened')
    },
    filteredTasks() {
      if (!this.tasks) return []
      const newObj = {}
      Object.keys(this.tasks).forEach(key => {
        newObj[key] = this.tasks[key].filter(task => {
          // Filtro de busca (search)
          if (this.filters.search) {
            const searchLower = this.filters.search.toLowerCase();
            if (!task.description.toLowerCase().includes(searchLower)) {
              return false;
            }
          }

          // Filtro de ID
          if (this.filters.id && Number(this.filters.id) !== Number(task.id)) {
            return false;
          }

          // Filtro de tipo (type)
          if (this.filters.type && task.type && task.type.id !== this.filters.type.id) {
            return false;
          }

          // Filtro de fila (queue)
          if (this.filters.queue && task.queue && task.queue.id !== this.filters.queue.id) {
            return false;
          }

          // Filtro de prioridade (priority)
          if (this.filters.priority && task.priority && task.priority.id !== this.filters.priority) {
            return false;
          }

          // Filtro de autor
          if (this.filters.author && (!task.author || task.author.id !== this.filters.author.id)) {
            return false;
          }

          // Filtro de responsável
          if (this.filters.assigned && (!task.assigned || task.assigned.id !== this.filters.assigned.id)) {
            return false;
          }

          // Filtro de projeto
          if (this.filters.project && (!task.project || task.project.id !== this.filters.project.id)) {
            return false;
          }

          // Filtro de projeto
          if (this.filters.cliente && (!task.cliente || task.cliente.id !== this.filters.cliente.id)) {
            return false;
          }

          // Filtro de intervalo de datas
          if (this.filters.data1 && this.filters.data2 && this.filters.tipoData) {
            const startDate = this.convertToDate(this.filters.data1);
            const endDate = this.convertToDate(this.filters.data2);

            const dateField = this.getDateField(task, this.filters.tipoData);
            if (!dateField) {
              return false;
            }

            const taskDate = this.convertToDateObject(dateField);

            // Verifica se a data da tarefa está no intervalo
            if (taskDate < startDate || taskDate > endDate) {
              return false;
            }
          }

          // Se passou em todos os filtros, inclui no resultado
          return true;
        })
      })
      return newObj
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      board()
          .then(response => {
            console.log(response.data)
            this.grids = response.data.status
            this.tasks = response.data.tasks
            this.$nextTick(() => {
              this.loading = false
              this.loadingKanban = false
              //this.loadNegocios(this.funil)
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    pesquisar () {
      return
    },
    // Converte uma data no formato brasileiro (DD/MM/YYYY) para um objeto Date
    convertToDate(brazilianDate) {
      const [day, month, year] = brazilianDate.split('/').map(Number);
      return new Date(year, month - 1, day); // Mês é 0-indexado no JavaScript
    },

    // Pega o campo de data da tarefa com base no tipo de data
    getDateField(task, tipoData) {
      return task[tipoData]?.date || null;
    },

    // Converte o campo de data do MongoDB para um objeto Date
    convertToDateObject(dateField) {
      return new Date(dateField); // Formato ISO é convertido automaticamente
    },
    dropzone(e, active, event = null) {
      this.dropzoneActive = active
      e.dropzoneActive = active
      if (event === 'dragstart') {
        e.dropzoneMoving = true
        this.dropactiveElement = e
      }
    },
    dropzoneDrop(event) {
      console.log('move')
    },
    onDragEnd(event) {
      console.log(event)
      // Exemplo: Informações do evento
      const { oldIndex, newIndex, item } = event;
      const taskId = item._underlying_vm_.id;
      const fromId = event.from.id;
      const toId = event.to.id;
      console.log(`Movendo item ${taskId} de status ${fromId} para ${toId}`)
      console.log(`Item movido de indice ${oldIndex} para ${newIndex}`, item);

      // Sua lógica de atualização
      if (oldIndex !== newIndex || fromId || toId) {
        boardTaskUpdate(taskId, {
          from: fromId,
          to: toId,
          order: newIndex
        })
      }
    },
    isOpen (task) {
      return task.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    openTask (t, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'console.tarefa', params: {id: t.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

