<template>
  <div class="h-full flex column kanban-module">

    <div class="kanban-container" v-if="!loading">
      <div class="kanban-content">
        <div class="kanban-funil-item" v-for="grid in filteredGrids">
          <div class="funil-header">
            <div class="funil-header-content">
              <div>
                <div class="etapa-title" :class="'etapa-s-code-' + grid.code">{{ grid.name }} <span style="font-size: 10px; margin-left: 5px">({{tasks[grid.id].length}})</span> </div>
              </div>
              <div class="etapa-subtitle">
                <!--                  <div class="m-r-sm">
                                    R$ {{getTotals(kanban[etapa.id]) | moeda}}
                                  </div>
                                  <div v-if="!!kanban[etapa.id].negocios">
                                     {{kanban[etapa.id].negocios.length}} negócios
                                  </div>-->
              </div>
            </div>
          </div>
          <draggable v-if="!loadingKanban" :list="tasks[grid.id]" :id="grid.id" :move="dropzoneDrop" @end="onDragEnd"
                     class="funil-cards" group="kanban">
            <div class="card" draggable="true"
                 v-for="(item, i) in tasks[grid.id]" :data-id="item.id" @click="openTask(item)">
              <context-menu-board :task="item" />
              <div class="card-content"
                   :style="`background-color: ${item.cor ? '#' + item.cor : ''}`">
                <div style="width: 100%;">
                  <span class="task-title">{{ item.description }}</span>
                  <div class="task-b-line1">
                    <div class="priority priority-3" v-if="item.priorityInternal === 3">Alta 🔥</div>
                    <div class="priority priority-4" v-if="item.priorityInternal === 4">Urgente ⚠️</div>
                    <div v-if="item.cache && item.cache.participants">
                      <task-group-persons :persons="item.cache.participants" no-click />
                    </div>
                    <span v-else></span>
                  </div>
                  <div v-if="item.deadline" class="task-due-line">
                    <span class="dl-dt">{{ item.deadline|formatDate('dd/MM/yyyy') }}</span> <span class="dl-hr">{{ item.deadline|formatDate('HH:mm') }}</span>
                    <span class="m-l-xs trask-atraso-lbl" v-if="isOpen(item) && atraso(item.deadline) > 0"><i
                        class="fas fa-exclamation-triangle m-r-xs"></i> {{ atraso(item.deadline) }} dia{{ atraso(item.deadline) > 1 ? 's' : '' }}</span>
                  </div>
<!--                  <div class="etapa-subtitle" style="margin: 10px 0px 10px 0;">
                    {{ (item.pessoa.name.split(' ')[0] ?? item.organizacao.name.split(' ')[0] ?? '') }}
                  </div>
                  <div style="display: flex; flex-direction: row;">
                    <u-icon name="user" type="fa" class="m-r  font-14 text-blue-grey-5"/>
                    <div data-test="pipeline-deal-value" class="">R$ {{ item.valor | moeda }}</div>
                  </div>-->
                </div>
              </div>
            </div>
            <u-btn icon="plus" icon-type="fa" icon-style="light"
                   color="grey-9" dense class="add-btn b-radius-3px full-width no-shadow" flat/>
          </draggable>
          <div class="funil-cards" v-else>
            <div class="card">
              <div class="card-content_">
                <u-fake-loader/>
                <u-fake-loader width="60%"/>
                <u-fake-loader width="10%"/>
                <u-fake-loader width="10%"/>
                <u-fake-loader width="10%"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kanban-container flex items-center justify-center" v-else>
      <sl-loading content="Tasks"/>
    </div>
  </div>
</template>

<script>
import {board, boardTaskUpdate} from "@/domain/tarefas/services"
import SlLoading from "@/components/layout/components/Loading"
import draggable from "vuedraggable"
import {UFakeLoader} from "uloc-vue"
import TaskGroupPersons from "components/sltarefas/components/others/TaskGroupPersons.vue";
import {differenceInHours, parseISO} from "date-fns";
import ContextMenuBoard from "components/console/components/suporte/tarefas/include/BoardContext.vue";

export default {
  name: "Kanban",
  provide: function () {
    return {
      container: this
    }
  },
  data() {
    return {
      loading: true,
      grids: [],
      tasks: [],
      loadingKanban: true,
      dropzoneActive: false
    }
  },
  created() {
    document.body.classList.add('kanban-module-body')
  },
  destroyed() {
    document.body.classList.remove('kanban-module-body')
  },
  components: {
    ContextMenuBoard,
    TaskGroupPersons,
    SlLoading,
    draggable,
    UFakeLoader
  },
  computed: {
    filteredGrids() {
      return this.grids.filter(s => s.type === 'opened')
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      board()
          .then(response => {
            console.log(response.data)
            this.grids = response.data.status
            this.tasks = response.data.tasks
            this.$nextTick(() => {
              this.loading = false
              this.loadingKanban = false
              //this.loadNegocios(this.funil)
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    dropzone(e, active, event = null) {
      this.dropzoneActive = active
      e.dropzoneActive = active
      if (event === 'dragstart') {
        e.dropzoneMoving = true
        this.dropactiveElement = e
      }
    },
    dropzoneDrop(event) {
      console.log('move')
    },
    onDragEnd(event) {
      console.log(event)
      // Exemplo: Informações do evento
      const { oldIndex, newIndex, item } = event;
      const taskId = item._underlying_vm_.id;
      const fromId = event.from.id;
      const toId = event.to.id;
      console.log(`Movendo item ${taskId} de status ${fromId} para ${toId}`)
      console.log(`Item movido de indice ${oldIndex} para ${newIndex}`, item);

      // Sua lógica de atualização
      if (oldIndex !== newIndex || fromId || toId) {
        boardTaskUpdate(taskId, {
          from: fromId,
          to: toId,
          order: newIndex
        })
      }
    },
    isOpen (task) {
      return task.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    openTask (t, target = '_blank') {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'console.tarefa', params: {id: t.id}})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

